<template>
  <div>
    <a :href="triviaLink" target="_blank">
      <img class="d-block w-100" type="image/webp" src="/img/icons/treasure.png" />
      <button class="w-100 btn btn-trivia">
        PLAY NOW
      </button>
    </a>
  </div>
</template>
  
<script>
export default {
  name: "Triviahunt",
  props: {
    triviaLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      myProfile: this.getProfile(),
      isLoggedIn: true,
      balance: 0
    };
  },
  watch: {
    isLoggedIn: "checkDisplay",
    balance: "checkDisplay"
  },
  // methods: {
  //   checkDisplay() {
  //     if (this.isLoggedIn && this.profile.b1 > 9282) {
  //     }
  //   }
  // },
  mounted: function () {
    var vm = this;
    vm.myProfile = vm.getProfile();

    vm.myProfile = vm.getProfile();
    // vm.initMqtt();
  },
  computed: {
    profile: function () {
      return this.myProfile;
    },
  }
};
</script>
  
<style>
/* Add your component-specific styles here if needed */
</style>
  